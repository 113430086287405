import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationStart, RoutesRecognized, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationCancel, NavigationError, ChildActivationStart, ChildActivationEnd } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { MatDialog } from '@angular/material/dialog';





@Component({
    selector   : 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls  : ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy
{
    collapsed: boolean;
    fuseConfig: any;

    @Output()
    input: EventEmitter<any>;


    @Output()
    closeSearch: EventEmitter<any>;

    @ViewChild('mainSearchInputField')
    mainSearchInputField;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        public dialog: MatDialog,
        private route: Router,
        private activatedRoute: ActivatedRoute
    )
    {
        // Set the defaults
        this.input = new EventEmitter();
        this.closeSearch = new EventEmitter();

        this.collapsed = true;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        //console.log(this.route.url);

        route.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
            } else if (event instanceof RoutesRecognized) {
              //console.log('RoutesRecognized event:', event);
              
            } else if (event instanceof RouteConfigLoadStart) {
              //console.log('RouteConfigLoadStart event:', event);
            } else if (event instanceof RouteConfigLoadEnd) {
              console.log('RouteConfigLoadEnd event:', event.route.path);

              if (event.route.path == 'search-result/:query') {
                //console.log("yes");
                this.expand();
              }
            } else if (event instanceof NavigationEnd) {
                /*console.log("Navigation End.", event);
                if (event.route.path == 'search-result/:query') {
                
                  }*/
            } else if (event instanceof NavigationCancel) {
            } else if (event instanceof NavigationError) {
            } else if (event instanceof ChildActivationStart) {
              // Load data specific to the child component here
            } else if (event instanceof ChildActivationEnd) {
              //console.log('ChildActivationEnd event:', event);
              // Perform any necessary cleanup tasks here
            }
          });


    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
            (config) => {
                this.fuseConfig = config;
            }
        );


    }

    /**
     * Funcion que al ejecutarse abre el modal para agregar un elemento
     * como para editarlo
     * @param {*} [element] data del chip
     * @memberof ListFilterComponent
     */
    openDialogFiltro(templateHistorico, element?: any): void {

        const dialogRef = this.dialog.open(templateHistorico, {
        width: '500px',
        data: element,
        panelClass: 'my-dialog'
        });

        // Una vez que se haya cerrado el modal
        dialogRef.afterClosed()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(result => {

        // Si se se le dio Aceptar en el modal
        if (result) {
            if (element.add === false) {
            result.add = true;
            //this.listChips.push(result);
            } else {
            /*this.listChips.map(flt => {
                if (flt.name === element.name) {
                flt.value = element.value;
                }
            });*/
            }
            //this.emitirEvento();
        }
        });
    }
    
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        console.log("se ha cerrado el search...");
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Collapse
     */
    collapse(): void
    {
        this.collapsed = true;
        this.closeSearch.emit("close");
    }

    /**
     * Expand
     */
    expand(): void
    {
        //console.log("aqui,....")
        this.collapsed = false;
        setTimeout(() => {
            this.mainSearchInputField.nativeElement.focus();
        });
    }

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        this.input.emit(event.target.value);

    }

}
