<ng-container *ngIf="tabsControlService.tabs[tabsControlService.selectedIndex]" [ngSwitch]="tabsControlService.tabs[tabsControlService.selectedIndex] && (tabsControlService.tabs[tabsControlService.selectedIndex].innerTabs[0].data['layout'] === 'tab' || tabsControlService.tabs[tabsControlService.selectedIndex].innerTabs[0].data['layout'] === 'innerTab') ? 1 : 2">
    <ng-container *ngSwitchCase="1">
        <!-- Pagina con Tabs o Inner Tas -->    
        <mat-tab-group class="main-ucs-mat-tab-group"
            [selectedIndex]="tabsControlService.selectedIndex"
            
            (selectedTabChange)="mainSelectedTabChange($event)"
            (selectedIndexChange)="mainSelectedIndexChange($event)">
            <mat-tab *ngFor="let tab of tabsControlService.tabs" [label]="tab.key">

                <ng-container *ngTemplateOutlet="tabConsultaIncluirEdicion;context:{innerTabs : tab.innerTabs, selectedIndex: tab.innerIndexSelected}" ></ng-container>

            </mat-tab>
        </mat-tab-group>
    </ng-container>
    
    <ng-container *ngSwitchCase="2">
        <!-- Pagina normal sin Tabs -->    
        <ng-container *ngComponentOutlet="tabsControlService.tabs[tabsControlService.selectedIndex].innerTabs[0].component;
                                        injector:tabsControlService.tabs[tabsControlService.selectedIndex].innerTabs[0].route._loadedConfig.module.injector;">
        </ng-container>
    </ng-container>

</ng-container>

<ng-template #tabConsultaIncluirEdicion let-innerTabs="innerTabs" let-selectedIndex="selectedIndex">

    <!-- Pagina standard FUSE LAYOUT para cada nuevo UCs que se abre en tabs -->
        
    <mat-tab-group 
        (selectedTabChange)="selectedTabChange($event)"
        (selectedIndexChange)="selectedIndexChange($event)"
        (focusChange)="focusChange($event)"
        [class]="hideTabBarHeader ? 'secondary-ucs-mat-tab-group invisible-tabs' : 'secondary-ucs-mat-tab-group show-tabs'" 
        dynamicHeight="false" 
        headerPosition="belowX" 
        [selectedIndex]="selectedIndex">

        <ng-container *ngFor="let innerTab of innerTabs;">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div class="col" style="margin-left: 20px;">{{innerTab.key}}</div>

                    <!--
                    <button *ngIf="innerTab.key !== 'Datos'" style="color:black" mat-icon-button (click)="closeTab(innerTabs, index)">
                        <mat-icon>close</mat-icon>
                    </button>
                    -->
                    
                    <!--
                    <i *ngIf="innerTab.key !== 'Datos'" class="material-icons" style="color:black" (click)="closeTab(innerTabs, innerTab.key)">close</i>
                    -->

                    <i *ngIf="innerTab.closeable === true" class="material-icons" style="color:black" (click)="closeTab(innerTabs, innerTab.key)">close</i>

                </ng-template>
            
                <ng-container *ngComponentOutlet="innerTab.component;
                                    injector:innerTab.route._loadedConfig.module.injector;
                                    ">
                </ng-container>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</ng-template>



