import { AuthService } from './../../../services/auth.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigationAdmin } from 'app/navigation/navigation.admin';
import { environment } from 'environments/environment';
import { EmpresaService } from 'app/services/empresa.service';
import { ToolbarService } from './toolbar.service';
import { UsuarioService } from 'app/pages/usuario/usuario.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    environment = environment;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    hiddenSearch: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    showProfile: boolean;
    userData: any = {};
    previousURL = null;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private route: Router,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private authService: AuthService,
        private toolbarService: ToolbarService,
        public empresaService: EmpresaService,
        public usuarioService: UsuarioService

    ) {

        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon : 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon : 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon : 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon : 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigationAdmin;

        if (true) {
            this.userData = {};
        } else {
            this.userData = {};
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    
    /**
     * On init
     */
    ngOnInit(): void
    {

        this.userData = JSON.parse(localStorage.getItem('ud'));

        // Subscribe to the config changes
        this._fuseConfigService.config
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((settings) => {
            this.horizontalNavbar = settings.layout.navbar.position === 'top';
            this.rightNavbar = settings.layout.navbar.position === 'right';
            this.hiddenNavbar = settings.layout.navbar.hidden === true;
            this.hiddenSearch = settings.layout.toolbar.hiddenSearch === true;
        });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        // Implementar Buscador
        //console.log("Search..." + value);
        //Implementar aqui el modal, para mostrar el historico de busquedas realizadas.
        //this.route.navigate(['/search-result', value]);
        this.toolbarService.searchQueryChanged.emit({
            type: 'keypress', value
        });

        
    }

    closeSearch(event): void
    {
        //console.log("Aqui cerrar la ventana");
                    
        if (this.previousURL != null) {
            this.route.navigate([this.previousURL]);
        } else {
            this.route.navigate(['/dashboard']);
            
        }
    }

    /**
     * Search
     *
     * @param value
     */
    openResult(event): void
    {
        const value = event.target.value;
        // Do your search here...
        // Implementar Buscador
        if (value) {
            //console.log(this.route.url);
            if (this.previousURL == null) {
                this.previousURL = this.route.url;
            }
            this.route.navigate(['/search-result', value]);
            
            this.toolbarService.searchQueryChanged.emit({
                type: 'enter', value
            });
            
        }
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }

    onLogout(): void {
        this.authService.logout();
        //this.route.navigate(['/']);
    }
    selectMenu(): void {
        console.log("this.ro", this.route.url);
        if (this.route.url.endsWith("empresa-select")){
            this.showProfile = false;
        } else {
            this.showProfile = true;
        }
    }
}
